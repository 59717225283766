/* z-indexes are large due to the fact that things might change
 * in the future. In order to account for scalability, this gives
 * room to make changes and not break the entire site, meaning 
 * we won't have to go in and refactor a lot of our code.
 */
/*  ----------------------------------------------------
    INPUTS
  ----------------------------------------------------- */
/*  ----------------------------------------------------
    CONTAINERS
  ----------------------------------------------------- */
/* -------------------
	Base result styles
-------------------- */
/* -------------------
	Variations
-------------------- */
/* -----------------------------------------------------
  Base styling
----------------------------------------------------- */
/* -----------------------------------------------------
  With secondary section
----------------------------------------------------- */
/* -----------------------------------------------------
  Checkbox tags
----------------------------------------------------- */
/* -----------------------------------------------------
  States
----------------------------------------------------- */
body.heatsinkshome scroll-bar {
  margin-top: var(--spacer1);
}
body.heatsinkshome scroll-bar .slide {
  min-height: 350px;
}

scroll-bar {
  --breakpoint-mobile: 650px;
  --slide-margin: var(--gap-y);
  --article-top-spacing: 0px;
  max-width: 1200px;
  grid-column: 2;
  grid-row: 1/span 3;
  height: fit-content;
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 1100;
}
scroll-bar.loading {
  opacity: 0;
}
scroll-bar .slide-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  grid-gap: calc(var(--gap) * 2);
  overflow-x: auto;
}
@media (min-width: 500px) {
  scroll-bar .slide-container {
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr) minmax(225px, 1fr));
  }
}
@media (min-width: 1500px) {
  scroll-bar .slide-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
scroll-bar .slide {
  position: relative;
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  flex-shrink: 0;
}
scroll-bar .slide .learn-more {
  --font-colour: #FFFFFF;
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  backdrop-filter: blur(4px);
  background-color: #DF3035;
  text-transform: uppercase;
  opacity: 1;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), text-decoration-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
scroll-bar .slide .learn-more::after {
  content: "0" attr(data-index);
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 800;
  font-size: 3.2em;
  line-height: 1;
  opacity: 0.2;
}
scroll-bar .slide .slide-image {
  width: 100%;
  height: 100%;
  display: block;
}
scroll-bar .slide .slide-image a {
  width: 100%;
  height: 100%;
  display: block;
}
scroll-bar .slide .slide-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
scroll-bar .slide .slide-image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, rgba(19, 31, 107, 0.65) 100%);
}
scroll-bar .slide .slide-drawer {
  --heading-colour: #FFFFFF;
  --font-colour: #FFFFFF;
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100%;
  padding: 2rem;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 0, 0, 0.6);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
scroll-bar .slide .slide-drawer h3 {
  text-transform: uppercase;
  margin-bottom: 2rem;
}
scroll-bar .slide .slide-drawer::after {
  content: "0" attr(data-index);
  position: absolute;
  top: -10px;
  right: 0;
  font-weight: 800;
  font-size: 5em;
  line-height: 1;
  color: #FFFFFF;
  opacity: 0.1;
}
scroll-bar .slide .slide-body {
  display: none;
}
scroll-bar .slide .polygon {
  background: red;
  clip-path: polygon(100% 0, 0, 40%);
}
@media (hover: hover) {
  scroll-bar .slide:not(.active):hover .learn-more {
    opacity: 0;
    pointer-events: none;
  }
  scroll-bar .slide:hover .slide-drawer {
    transform: translateY(-100%);
  }
}
scroll-bar .slide:not(.active) {
  cursor: pointer;
}
scroll-bar .slide.active {
  --article-top-spacing: calc(-1 * var(--slide-margin));
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  width: 500px;
  height: 100%;
}
scroll-bar .slide.active::after {
  content: "0" attr(data-index);
  position: absolute;
  top: 3rem;
  right: 6rem;
  font-weight: 800;
  font-size: 8em;
  line-height: 1;
  opacity: 0.4;
  color: #FFFFFF;
}
scroll-bar .slide.active .slide-drawer {
  display: none;
}
scroll-bar .slide.active .slide-image {
  height: 52%;
}
scroll-bar .slide.active .slide-body {
  display: grid;
  grid-template-rows: auto;
  position: relative;
  place-content: start;
  height: 100%;
  padding: calc(var(--gap) * 2) calc(var(--gap) * 2) 200px;
  background: #FFFFFF;
  overflow: hidden;
}
scroll-bar .slide.active .slide-body .subheading,
scroll-bar .slide.active .slide-body .heading {
  text-transform: uppercase;
}
scroll-bar .slide.active .slide-body .subheading {
  order: 1;
  color: #DF3035;
}
scroll-bar .slide.active .slide-body .heading {
  order: 2;
  font-size: 3.125em;
  font-size: clamp(2.5em, 5vw, 3.125em);
  line-height: 1.2;
  font-weight: 800;
  text-transform: uppercase;
}
scroll-bar .slide.active .slide-body .heading a {
  color: #131f6b;
}
@media (hover: hover) {
  scroll-bar .slide.active .slide-body .heading a:hover {
    text-decoration: underline;
  }
}
scroll-bar .slide.active .slide-body .content {
  order: 3;
  margin-top: calc(var(--gap) * 2);
  color: #1E1A1A;
}
scroll-bar .slide.active .learn-more {
  bottom: 10%;
  background-color: #DF3035;
  padding-right: 2rem;
}
scroll-bar .slide.active .learn-more::after {
  content: none;
}
scroll-bar .slide .hide {
  display: none;
}
scroll-bar .slide .transition {
  opacity: 0;
}
scroll-bar .slide .fade {
  transition: 0.6s opacity 0s;
}

scroll-bar.deactivated {
  --article-top-spacing: 0 !important;
  grid-row: 3;
  position: relative;
  left: 0;
  display: flex;
  grid-gap: var(--gutter2);
  width: 100%;
  height: 100%;
  padding-bottom: var(--gap-y);
}
scroll-bar.deactivated .slide-container {
  flex-wrap: wrap;
  height: fit-content;
  padding: 0;
  margin-bottom: 0;
}

@media (max-width: 650px) {
  scroll-bar {
    --cell-min-width: 100%;
    max-width: unset;
    padding-bottom: 0;
  }
  scroll-bar .slide:not(.active) {
    width: 100%;
    height: unset;
    aspect-ratio: 16/9;
  }
}