@use '../_variables' as *;


@mixin angled-clip() {
	clip-path: polygon(0% 100%, 15px 0%, 100% 0%, 100% 100%);
}

@mixin square($length) {
	width: $length;
	height: $length;
}

@mixin featured-badge($colour : $orange) {
	position: absolute;
	top: 0;
	padding: 0.7rem 2rem 0.7rem 3rem;
	// Fixed height slightly fixes blurry text issue in chrome
	height: 39px;
	background: $colour;
	color: $white;
	// -0.5px slightly fixes blurry text issue in chrome
	transform: translate(0, -0.5px) translateX(-100%) translateY(-100%) rotate(-90deg);
	transform-origin: bottom right;
	@include angled-clip();
}

@mixin disabled($opacity : 0.4) {
  opacity: $opacity;
  pointer-events: none;
}

@mixin text-ellipsis() {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}