@use '_modules' as *;
@use 'sass:list';

$breakpoint-mobile: 650px;

body.heatsinkshome{
  scroll-bar{
    margin-top: $spacer;
    .slide{
      min-height: 350px;
    }
  }
}

scroll-bar {
  --breakpoint-mobile: #{$breakpoint-mobile};
  --slide-margin: var(--gap-y);
  --article-top-spacing: 0px;
  max-width: 1200px;

  // position: absolute;
  // top: 0;
  // left: calc(var(--navigation-bar-width) + var(--gap-x));
  // width: calc(100% - var(--navigation-bar-width) - (var(--gap-x)));

  grid-column: 2;
  grid-row: 1 / span 3;

  height: fit-content;
  // overflow: hidden;
  transition: opacity 0.6s $ease-out-cubic;

  z-index: $z-index-scroll-bar;

  &.loading {
    opacity: 0;
  }

  .slide-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
    grid-gap: $gap2;
    overflow-x: auto;

    @media (min-width: 500px) {
      grid-template-columns: repeat(auto-fit, minmax(225px, 1fr) minmax(225px, 1fr));

    }

    @media (min-width: 1500px) {
      grid-template-columns: repeat(4, 1fr);  
    }
  }

  // Main slide element
  .slide {
    position: relative;
    transition: all 0.8s $ease-out-quart;
    @include page-article();

    &:not(.active) {
      cursor: pointer;
    }

    &.active {
      --article-top-spacing: calc(-1 * var(--slide-margin));

      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      width: 500px;
      height: 100%;

      &::after {
        content: '0' attr(data-index);
        position: absolute;
        top: 3rem;
        right: 6rem;
        font-weight: 800;
        font-size: 8em;
        line-height: 1;
        opacity: 0.4;
        color: $white;
      }

      .slide-drawer {
        display: none;
      }

      .slide-image {
        height: 52%;
      }

      .slide-body {
        display: grid;
        grid-template-rows: auto;
        position: relative;
        place-content: start;
        height: 100%;
        padding: $gap2 $gap2 200px;
        background: $white;
        overflow: hidden;

        .subheading,
        .heading {
          text-transform: uppercase;
        }

        .subheading {
          order: 1;
          color: $red;
        }

        .heading {
          order: 2;
          @include heading-h1;
          font-weight: 800;
          text-transform: uppercase;

          a {
            color: $blue;

            @include hover {
              text-decoration: underline;
            }
          }
        }

        .content {
          order: 3;
          margin-top: $gap2;
          color: $black;
        }
      }

      .learn-more {
        bottom: 10%;
        background-color: $red;
        padding-right: 2rem;

        &::after {
          content: none;
        }
      }
    }
    .hide {
      display: none;
    }
    .transition {
      opacity: 0;
    }
    .fade {
      transition: 0.6s opacity 0s;
    }
  }
}

scroll-bar.deactivated {
  --article-top-spacing: 0 !important;

  grid-row: 3;

  position: relative;
  left: 0;
  display: flex;
  grid-gap: var(--gutter2);
  width: 100%;
  height: 100%;
  padding-bottom: var(--gap-y);

  .slide-container {
    flex-wrap: wrap;
    height: fit-content;
    padding: 0;
    margin-bottom: 0;
  }
}

@include break-in($breakpoint-mobile) {
  scroll-bar {
    --cell-min-width: 100%;
    max-width: unset;

    padding-bottom: 0;

    .slide:not(.active) {
      width: 100%;
      height: unset;
      aspect-ratio: 16 / 9;
    }
  }
}
