@use '../_variables' as *;
@use '../_helpers/' as *;

$card-image-gradient: linear-gradient(to bottom, rgba(255,255,255,0) 30%, rgba($blue, 0.65) 100%);


@mixin page-article() {
  overflow: hidden;
  flex-shrink: 0;

  .learn-more {
    --font-colour: #{$white};

    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    backdrop-filter: blur(4px);
    background-color: #DF3035; 
    text-transform: uppercase;
    opacity: 1;
    transition: color $transition, text-decoration-color $transition;

    &::after {
      content: '0' attr(data-index);
      position: absolute;
      bottom: 0;
      right: 0;
      font-weight: 800;
      font-size: 3.2em;
      line-height: 1;
      opacity: 0.2;
    }
  }

  .slide-image {
    @include square(100%);
    display: block;

    a {
      @include square(100%);
      display: block;
    }

    img {
      display: block;
      @include square(100%);
      object-fit: cover;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: $card-image-gradient;
    }
  }

  .slide-drawer {
    --heading-colour: #{$white};
    --font-colour: #{$white};

    position: absolute;
    top: 100%;
    width: 100%;
    height: 100%;
    padding: 2rem;
    backdrop-filter: blur(4px);
    background-color: rgba(red, 0.6); 
    transition: transform 0.3s $ease-in-out-cubic;

    h3 {
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    &::after {
      content: '0' attr(data-index);
      position: absolute;
      top: -10px;
      right: 0;
      font-weight: 800;
      font-size: 5em;
      line-height: 1;
      color: $white;
      opacity: 0.1;
    }
  }

  .slide-body {
    display: none;
  }

  .polygon {
    background: red;
    clip-path: polygon(100% 0, 0, 40%);
  }

  @media (hover: hover) {
    &:not(.active):hover {
      .learn-more   { opacity: 0; pointer-events: none; }
    }
    &:hover {
      .slide-drawer { transform: translateY(-100%) }
    }
  }
}